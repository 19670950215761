import clsx from "clsx"
import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { aboutURL, newsBlogsURL } from "../common/utils/urls"
import { parseHTML } from "../common/utils/utils"
import AppraisalCard from "../components/AppraisalCard/AppraisalCard"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import BannerNews from "../components/BannerNews/BannerNews"
import Layout from "../components/Layout/Layout"
import NegoCard from "../components/NegoCard/NegoCard"
import OtherNewsSlider from "../components/OtherNewsSlider/OtherNewsSlider"
import SEO from "../components/SEO/seo"
import ShareIcons from "../components/SocialShare/ShareIcons"
import Animation from "../components/elements/Animation"
import GGFXImage from "../components/elements/GGFXImage"
import "../styles/other/NewsDetailTemplate.scss"

const NewsDetailTemplate = ({ data }) => {
  if (!data?.strapiBlog) return null

  const news = data.strapiBlog
  const contents = news.blog_content
  const author = news.author
  const metaTitle = `${news.title}`
  const metaDescription = `Read about ${news.title} here and subscribe to our newsletter to stay up-to-date about everything going on at Christopher Hodgson.`
  const breadcrumbURLs = [
    {
      url: aboutURL,
      label: "About",
    },
    {
      url: newsBlogsURL,
      label: "News",
    },
    {
      url: `${newsBlogsURL}${news.slug}/`,
      label: news.title,
    },
  ]

  let processedImages = news?.imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div className={clsx("news-template")}>
      <Layout
        breadcrumbURLs={breadcrumbURLs}
        footer={"dark"}
        popular_search={{ title: "category1" }}
      >
        <SEO title={metaTitle} description={metaDescription} />
        <BannerNews news={news} />
        <Container className="content-section">
          <div className="left-section">
            {contents.map(content => (
              <>
                {content.__typename ===
                  "STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK" && (
                  <Animation animateInType={"up"} className="content-img">
                    <GGFXImage
                      ImageSrc={content.image?.length > 0 && content.image[0]}
                      title={news.title}
                      altText={news.title}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={"blog.image.detail_image"}
                      strapi_id={news.strapi_id}
                    />
                  </Animation>
                )}
                {content.__typename ===
                  "STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK" && (
                  <Animation animateInType={"up"} className="content-text">
                    {parseHTML(content.content.data.content)}
                  </Animation>
                )}
              </>
            ))}
            <Animation animateInType={"up"} className="share-section">
              <p className="title">Share this post</p>
              <div className="icons-section">
                <ShareIcons mediaImg={news.tile_image.url} />
              </div>
            </Animation>
          </div>
          <div className="right-section">
            <div className="sticky-section">
              {!_.isEmpty(author) && <NegoCard team={author} sales_contact={news.phone} />}
              <AppraisalCard />
            </div>
          </div>
        </Container>
        <OtherNewsSlider newsID={news.strapi_id} />
        <AppraisalComponent />
      </Layout>
    </div>
  )
}

export default NewsDetailTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiBlog(id: { eq: $page_id }) {
      ...NewsFragment
      blog_content {
        ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK {
          __typename
          id
          image {
            url
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK {
          __typename
          id
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`
